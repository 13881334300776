header,
.slick-prev,
.slick-next,
.main-footer,
.sticky-overlay,
.sticky-btns,
.sticky-bottom-container,
.header-img-container,
#overlayBooking{
    display: none !important;
    position:absolute;
    left:-1999999px;
}
.slick-track {
    width: 100% !important;
    transform: none !important;
    display: table !important;
    margin: 0 !important;
    padding: 0 !important;
    table-layout: fixed !important;
}
.slick-slide {
    display: table-cell !important;
    padding: 0 !important;
    vertical-align: top !important;
    width: 100% !important;
    float: none !important;
}
.slick-slide[aria-hidden="true"] {
    display: none !important;
}

/** prevent fade in effects (portal) **/
.js-animate {
    opacity: 1 !important;
}

/** affix booking bar**/
.selection-info.is-affix {
    position: relative;
}

/** newsletter **/
.newsletter-slide {
    display: none;
}

/** (leaflet) map **/
.arrival-map-wrapper {
    page-break-before: always;
}
.leaflet-container {
    background: transparent;
}
.leaflet-control-container {
    display: none;
}

/** show collapsed elements (rooms, etc.) **/
.collapse:not(.show) {
    display: block;
}



h1,
h2,
h3,
h4{
    page-break-after: avoid;
}

tr,
img,
.teaser,
footer {
    page-break-inside: avoid;
}




/* reset bootstrap default stuff */
a[href]:after,
abbr[title]:after,
a[href^="#"]:after,
a[href^="javascript:"]:after {
    content: "";
}


.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
}
.col-md-12 {
    width: 100%;
}
.col-md-11 {
    width: 91.66666667%;
}
.col-md-10 {
    width: 83.33333333%;
}
.col-md-9 {
    width: 75%;
}
.col-md-8 {
    width: 66.66666667%;
}
.col-md-7 {
    width: 58.33333333%;
}
.col-md-6 {
    width: 50%;
}
.col-md-5 {
    width: 41.66666667%;
}
.col-md-4 {
    width: 33.33333333%;
}
.col-md-3 {
    width: 25%;
}
.col-md-2 {
    width: 16.66666667%;
}
.col-md-1 {
    width: 8.33333333%;
}
